import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, PostCard } from '../components/common'
import { MetaData } from '../components/common/meta'

import { Avatar } from '../components/common/meta/Icon'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

import Toc from '../components/common/Toc'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/

const Post = ({ data, location }) => {
    const post = data.ghostPost
    const readingTime = readingTimeHelper(post)
    const relatedPost = data.allGhostPost.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>

                <article className="article">

                    <header className="article-header gh-canvas">
                        {post.primary_tag && 
                            <section className="article-tag">
                                <Link to={`/tag/${post.primary_tag.slug}`}>{post.primary_tag.name}</Link>
                            </section>
                        }

                        <h1 className="article-title">{post.title}</h1>

                        {post.custom_excerpt && 
                            <p className="article-excerpt">{post.custom_excerpt}</p>
                        }

                        <div className="article-byline">
                            <section className="article-byline-content">
                                <ul className="author-list">
                                    {post.authors.map(({name, profile_image, slug}) => (
                                        <li className="author-list-item" key={slug}>
                                            {!!profile_image ? <Link to={`/author/${slug}`} className="author-avatar">
                                                <img className="author-profile-image" src={profile_image} alt={name} />
                                            </Link> : <Link to={`/author/${slug}`} className="author-avatar author-profile-image"><Avatar /></Link> }
                                        </li>
                                    ))}
                                </ul>
                                <div className="article-byline-meta">
                                    <h4 className="author-name">{post.authors.map(({name, slug}) => (
                                        <span key={slug}>{name}</span>
                                    ))}</h4>
                                    <div className="byline-meta-content">
                                    
                                        <time className="byline-meta-date" dateTime={post.published_at}>{post.published_at_pretty}</time>
                                        <span className="byline-reading-time"><span className="bull">&bull;</span> {readingTime}</span>
                                    </div>
                                </div>
                            </section>
                        </div>
                        
                        {post.feature_image && 
                            <figure className="article-image">
                                <img
                                    srcSet={`${post.feature_image} 300w,
                                        ${post.feature_image} 600w,
                                        ${post.feature_image} 1000w,
                                        ${post.feature_image} 2000w`}
                                    sizes="(min-width: 1400px) 1400px, 92vw"
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        }

                        <Toc post={post} />
                    </header>
                    
                    <section className="gh-content gh-canvas" dangerouslySetInnerHTML={{ __html: post.html }} />
                </article>

                {/* Read more links, just above the footer */}
                {!!relatedPost && 
                    <aside className="read-more-wrap">
                        <div className="read-more inner">
                            {relatedPost.map(({ node }, index) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} index={index} home={false} />
                            ))}
                        </div>
                    </aside>
                }
                
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!, $slug_tag: String) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
            childHtmlRehype {
                tableOfContents
            }
        }

        allGhostPost(
            filter: {tags: {elemMatch: {slug: {eq: $slug_tag}}}, slug: { ne: $slug }},
            limit: 3
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }

    }
`
