import React from 'react'

const Toc = ({ post }) => {

    return !!post.childHtmlRehype.tableOfContents && (
        <div className="outline-box">
            <p>Xem nhanh</p>
            <ol className="outline-nav">
                {post.childHtmlRehype.tableOfContents.map((node) => (
                    <li key={node.id}><a href={`#${node.id}`}>{node.heading}</a></li>
                ))}
            </ol>
        </div>
    )
}

export default Toc